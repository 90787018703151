import {Switch, Route, NavLink, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import {useEffect, useRef, useState, useCallback} from 'react';
import ReactDOM from 'react-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

import DATA from './data';
import {BACKEND} from './config';

import ImgChen from './assets/committee-chen.png';
import ImgZhang from './assets/committee-zhang.png';
import ImgBalaji from './assets/committee-balaji.png';
import ImgHoefler from './assets/committee-hoefler.png';

import ImgLogo from './assets/logo.png';
import ImgArrow from './assets/arrow.png';
import ImgSuccess from './assets/succeed.png';
import ImgFailed from './assets/fail.png';

import './styles/home.scss';
import './styles/ranking.scss';
import './styles/dialog.scss';
import './styles/input.scss';

function CommitteeImg({img}) {
    return <div className="committee-img">
        <img src={img}/>
    </div>
}

const SUBMISSION_DIALOG_STATE = Object.freeze({
    DEFAULT: 'DEFAULT',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
});


const TOP_RATIO = 0.8;
const BAR_HIDDEN_THRESHOLD = 10;
const LOC_SHOWN_TIMEOUT = 5000;
const FILE_LIMIT = 10 * 1000 * 1000;

function Trigger({name, update, targets, addTarget}) {
    useEffect(() => {
        const listener = () => {
            if (!targets.current[name]) return;
            const cur = targets.current[name];
            const bbox = cur.getBoundingClientRect();
            if (name === 'about') console.log(bbox.y);
            update(orig => ({...orig, [name]: bbox.y < 65}));
        };

        window.addEventListener('scroll', listener);

        return () => window.removeEventListener('scroll', listener);
    }, []);

    return <div ref={r => addTarget(name, r)}/>
}

function Nav({name, loc, targets, children}) {
    const active = Array.isArray(name) ? name.includes(loc) : name === loc;
    return (
        <div className={clsx({active})} onClick={() => {
            const target = Array.isArray(name) ? name[0] : name;

            const ref = targets.current[target];
            if (!ref) return;
            const top = ref.offsetTop;
            window.scrollTo({
                top,
                behavior: 'smooth',
            });
        }}>{children}</div>
    );
}

const TITLES = {
    home: 'WHAT WE DO',
    intro: 'WHAT WE DO',
    benchmark: 'LIST',
    submission: 'SUBMISSION',
    about: 'ABOUT',
};

function App() {
    const THRESHOLD = window.innerHeight * TOP_RATIO;

    const [top, setTop] = useState(window.scrollY <= THRESHOLD);
    const [barHidden, setBarHidden] = useState(window.scrollY <= BAR_HIDDEN_THRESHOLD);
    const [scrollRatio, setScrollRatio] = useState(window.scrollY / (document.documentElement.scrollHeight - window.innerHeight));
    useEffect(() => {
        const listener = () => {
            const THRESHOLD = window.innerHeight * TOP_RATIO;
            setTop(window.scrollY <= THRESHOLD);
            setBarHidden(window.scrollY <= BAR_HIDDEN_THRESHOLD);
            setScrollRatio(window.scrollY / (document.documentElement.scrollHeight - window.innerHeight));
        };

        window.addEventListener('scroll', listener);

        return () => window.removeEventListener('scroll', listener);
    }, []);

    const [locs, setLocs] = useState({'home': true});
    const loc = ['home', 'intro', 'benchmark', 'submission', 'about'].reverse().find(e => locs[e]) || 'home';
    const locIdx = ['home', 'intro', 'benchmark', 'submission', 'about'].findIndex((e) => e === loc);
    const targets = useRef({});
    const [waypoints, setWaypoints] = useState({});
    const addTarget = useCallback((name, target) => {
        if (targets[name] === target) return;
        targets.current[name] = target;

        if (!target) return;

        setWaypoints(wp => {
            if (wp[name] !== undefined) return wp;
            const waypoint = target.offsetTop / (document.documentElement.scrollHeight - window.innerHeight);
            console.log('Waypoing', waypoint);
            return {
                ...wp,
                [name]: waypoint,
            }
        });
    }, [setWaypoints]);

    const location = useLocation();

    const [locShown, setLocShown] = useState(false);
    const pendingClearing = useRef(null);
    useEffect(() => {
        setLocShown(true);

        if (pendingClearing.current !== null) clearTimeout(pendingClearing.current);
        pendingClearing.current = setTimeout(() => {
            pendingClearing.current = null;
            setLocShown(false);
        }, LOC_SHOWN_TIMEOUT);
    }, [loc]);

    const [submitting, setSubmitting] = useState(false);
    const [submissionState, setSubmissionState] = useState(SUBMISSION_DIALOG_STATE.DEFAULT);
    const blocker = useCallback(e => {
        e.stopPropagation();
    }, []);

    // const [list, setList] = useState(0);
    const [pages, setPages] = useState(new Array(DATA.length).fill(0));

    function updatePage(i, p) {
        const newPages = [...pages];
        newPages[i] = p;
        setPages(newPages);
    }

    const MAX_PAGES = DATA.map(e => Math.ceil(e.data.length / 10) - 1);

    const nameInput = useRef();
    const emailInput = useRef();
    const orgInput = useRef();
    const phoneInput = useRef();
    const fileInput = useRef();

    const [nameError, setNameError] = useState(false);
    const [orgError, setOrgError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [fileError, setFileError] = useState(false);

    const checkFile = useCallback(e => {
        const file = fileInput.current?.files?.[0] ?? null;
        if (!file) {
            setFileError(true);
            return;
        }

        setFileError(file.size > FILE_LIMIT)
    });

    const [checkInputGate, setCheckInputGate] = useState(false);

    const checkInput = useCallback(() => {
        if (!checkInputGate) return;

        const name = nameInput.current?.value;
        const email = emailInput.current?.value;
        const org = orgInput.current?.value;
        const phone = phoneInput.current?.value;

        setNameError(!name);
        setOrgError(!org);
        setEmailError(!email || !email.match(/.+@.+/));
        setPhoneError(!phone || !phone.match(/[0-9\-]/));
    });

    const doSubmit = useCallback(async () => {
        if (fileError) return;

        setCheckInputGate(true);
        checkFile();

        // Manually check input
        const name = nameInput.current?.value;
        const email = emailInput.current?.value;
        const org = orgInput.current?.value;
        const phone = phoneInput.current?.value;
        const file = fileInput.current?.files?.[0];

        setNameError(!name);
        setOrgError(!org);
        setEmailError(!email || !email.match(/.+@.+/));
        setPhoneError(!phone || !phone.match(/[0-9\-]/));

        if (!name || !email || !org || !phone || !file) {
            return;
        }

        if (!email.match(/.+@.+/) || !phone.match(/[0-9\-]+/)) {
            return;
        }

        const data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('org', org);
        data.append('phone', phone);
        data.append('file', file);

        try {
            const resp = await fetch(`${BACKEND}/submit`, {
                method: 'POST',
                body: data,
            });

            if (resp.status !== 204) {
                setSubmissionState(SUBMISSION_DIALOG_STATE.FAILED);
            } else {
                setSubmissionState(SUBMISSION_DIALOG_STATE.SUCCESS);
            }
        } catch (e) {
            setSubmissionState(SUBMISSION_DIALOG_STATE.FAILED);
        }
    }, [fileError]);

    let submissionDialog = (
        <div className="dialog" onClick={blocker}>
            <div className="dialog-header">
                <h2>FILL IN YOUR INFORMATION</h2>
            </div>

            <div className="input-group">
                <label htmlFor="name">Name</label>
                <div className={clsx("input-mask", {error: nameError})} data-error-text="Missing required field">
                    <input id="name" ref={nameInput} onChange={checkInput}/>
                </div>
            </div>

            <div className="input-group">
                <label htmlFor="org">Organization</label>
                <div className={clsx("input-mask", {error: orgError})} data-error-text="Missing required field">
                    <input id="org" ref={orgInput} onChange={checkInput}/>
                </div>
            </div>

            <div className="input-group">
                <label htmlFor="email">Email</label>
                <div className={clsx("input-mask", {error: emailError})} data-error-text="Incorrect format">
                    <input id="email" ref={emailInput} onChange={checkInput}/>
                </div>
            </div>

            <div className="input-group">
                <label htmlFor="phone">Phone Number</label>
                <div className={clsx("input-mask", {error: phoneError})} data-error-text="Incorrect format">
                    <input id="phone" ref={phoneInput} onChange={checkInput}/>
                </div>
            </div>

            <div className="input-group">
                <label htmlFor="result">Select A File Upload</label>
                <div className={clsx("input-mask", {error: fileError})}
                     data-error-text="File required and cannot exceed 10M">
                    <input id="result" type="file" ref={fileInput} onChange={checkFile}/>
                </div>
            </div>

            <div className="dialog-actions">
                <button className="dialog-action primary" onClick={doSubmit}>Submit</button>
                <button className="dialog-action" onClick={() => setSubmitting(false)}>Cancel</button>
            </div>
        </div>
    );

    if (submissionState === SUBMISSION_DIALOG_STATE.SUCCESS) {
        submissionDialog = (
            <div className="dialog dialog-info" onClick={blocker}>
                <img className="dialog-state-img" src={ImgSuccess}/>

                <div className="dialog-header dialog-header-big">
                    <h2>Congratulations</h2>
                </div>

                <p className="dialog-text">
                    Congratulations on your successful submission. <br/>
                    We&#39;ll contact you via email later
                </p>

                <div className="dialog-actions">
                    <button className="dialog-action primary dialog-action-wide"
                            onClick={() => setSubmitting(false)}>OK
                    </button>
                </div>
            </div>
        );
    } else if (submissionState === SUBMISSION_DIALOG_STATE.FAILED) {
        submissionDialog = (
            <div className="dialog dialog-info" onClick={blocker}>
                <img className="dialog-state-img" src={ImgFailed}/>

                <div className="dialog-header dialog-header-big">
                    <h2>Sorry</h2>
                </div>

                <p className="dialog-text">
                    Sorry, the upload failed. <br/>
                    Please go back and try again,thanks
                </p>

                <div className="dialog-actions">
                    <button className="dialog-action primary dialog-action-wide"
                            onClick={() => setSubmissionState(SUBMISSION_DIALOG_STATE.DEFAULT)}>Fill Again
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="app">
            <nav className={clsx("bottom-bar", {top, hidden: barHidden})}>
                <div className="bottom-bar-inner">
                    <img src={ImgLogo} className={clsx("brand", {hidden: location.pathname === '/' && top})}/>
                    <div className="spanner"/>
                    <div className="navs">
                        <Nav targets={targets} name={["home", "intro"]} loc={loc}>HOME</Nav>
                        <Nav targets={targets} name="benchmark" loc={loc}>BENCHMARK</Nav>
                        <Nav targets={targets} name="submission" loc={loc}>SUBMISSION</Nav>
                        <Nav targets={targets} name="about" loc={loc}>ABOUT</Nav>
                    </div>
                </div>
            </nav>
            <div className="progress">
                <div className="progress-waypoints">
                    {Object.keys(waypoints).map(k => (
                        <div key={k} className={clsx("progress-waypoint", {white: top})}/>
                    ))}
                </div>
                <div className={clsx("progress-indicator", {white: top})} style={{
                    transform: `translateY(calc(-50% + ${(locIdx) * 60 / 4 + 20}vh))`
                }}>
                    <div className="progress-indicator-bar"/>
                    <div className={clsx("progress-indicator-text", {shown: locShown && TITLES[loc] !== ''})}>
                        {TITLES[loc]}
                    </div>
                </div>
            </div>

            <Trigger addTarget={addTarget} targets={targets} name="home" update={setLocs}/>

            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    classNames="page"
                    timeout={400}
                >
                    <div>
                        <div className="page">
                            <div className="home-banner">
                                <div className="home-banner-right">
                                    <div className="home-brand">
                                        AIPERF
                                    </div>

                                    <div className="home-slogan">
                                        Automated machine learning as AI-HPC benchmarks
                                    </div>

                                    <a className="no-ul" href="https://arxiv.org/pdf/2008.07141">
                                        <button className="home-button">
                                            READ THE PAPER
                                        </button>
                                    </a>
                                </div>


                                <div className="read-more">
                                    <div className={clsx("read-more-line", {hidden: !top})}/>
                                    <div className="read-more-text">SCROLL DOWN</div>
                                </div>
                            </div>

                            <main>
                                <Trigger addTarget={addTarget} targets={targets} name="intro" update={setLocs}/>
                                <div className="section-spacer"/>

                                <div className="intro">
                                    <h2>WHAT IS AIPERF</h2>
                                    <div className="title-split"/>
                                    <p class="justify-hypens">
                                        AIPerf is a suite of end-to-end benchmarks utilizing state-of-the-art machine
                                        learning techniques and real-world tasks to measure the performance of various
                                        AI machines. It represents real AI scenarios, and scales auto-adaptively to
                                        various scales of machines.
                                    </p>
                                    <p class="justify-hypens">
                                        The automl pipeline (including NAS, HPO, etc.) is implemented in a highly
                                        parallel and flexible way to ensure the efficiency and optimization potential on
                                        diverse systems with customizable configurations. We utilize operations per
                                        second (OPS), which is measured in an analytical and systematic approach, as the
                                        major metric to quantify the AI performance.With flexible workload and single
                                        metric, our benchmark can scale and rank AI-HPC easily.
                                    </p>
                                    <p class="justify-hypens">
                                        The LLM benchmark, targeting a trending model structure of large language model,
                                        requires both powerful computing perfmance and strong communication capability
                                        to achieve high score. With support of mixed-precision training, configurable
                                        parallel strategies and scalable model size, efforts from system-scale and
                                        accelerator-level optimizations are highly appreciated. Systems with high score
                                        on this benchmark have promising capacity for training modern large language
                                        models efficiently.
                                    </p>
                                    <p class="justify-hypens">
                                        The Inference benchmark encompasses evaluation applications from multiple
                                        domains, such as question answering, visual question answering, image
                                        recognition, text-to-image generation, and text-to-video generation. Users can
                                        optimize system performance by adjusting various parameters for each
                                        application. Additionally, users can employ customized inference engines to
                                        fully exploit the capabilities of underlying hardwares (Note: submission of
                                        source code or source code level verification is required when using any
                                        customized inference engine). Through encouraging software and hardware combined
                                        optimization, and enforcing comprehensive evaluations, the benchmark aims to
                                        promote the development of AI inference systems.
                                    </p>
                                    <p class="justify-hypens">
                                        Following the explosive demand of local DeepSeek R1/V3 deployments, the DeepSeek
                                        benchmark provides the first comprehensive evaluation mechanism for "DeepSeek
                                        integrated systems". The benchmark employs a three-dimensional methodology that
                                        measures system throughput under specified latency requirements, accuracy
                                        thresholds, and transparent market pricing. Additionally, it enables system
                                        performance evaluation when running both DeepSeek V3 and R1 671B models across
                                        various precision formats (FP8/FP16/BF16 or INT8/INT4), with rigorous
                                        verification protocols. By establishing this standardized evaluation approach,
                                        organizations can make informed decisions when selecting DeepSeek deployment
                                        solutions, thereby accelerating the advancement of the large language model
                                        ecosystem. </p>

                                </div>

                                <Trigger addTarget={addTarget} targets={targets} name="benchmark" update={setLocs}/>
                                <div className="section-spacer"/>

                                {DATA.map((d, i) => (
                                    <>
                                        <div class="ranklist">
                                            <h2>
                                                {d.name}
                                            </h2>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Rank</th>
                                                    {
                                                        d.columns.map((col) => {
                                                            return <th>{col.display}</th>
                                                        })
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {new Array(Math.max(Math.min(10, d.data.length), 1)).fill(null).map((_, j) => {
                                                    const idx = pages[i] * 10 + j;
                                                    const data = d.data[idx];
                                                    if (!data) {
                                                        return <tr key={i} className="placeholder"/>
                                                    }

                                                    return (
                                                        <tr key={i}>
                                                            <td>{idx + 1}</td>

                                                            {
                                                                d.columns.map((col) => {
                                                                    if (col.translate) {
                                                                        return col.translate(data[col.key])
                                                                    } else {
                                                                        return <td>{data[col.key]}</td>
                                                                    }
                                                                })
                                                            }


                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </table>

                                            <div className="pager">
                                                <div className="pager-num">{pages[i] + 1} - {MAX_PAGES[i] + 1}</div>
                                                <div
                                                    className={clsx("pager-btn", {'pager-btn-disabled': pages[i] === 0})}
                                                    onClick={() => updatePage(i, Math.max(pages[i] - 1, 0))}>
                                                    <i className="material-icons">keyboard_arrow_left</i>
                                                </div>
                                                <div
                                                    className={clsx("pager-btn", {'pager-btn-disabled': pages[i] === MAX_PAGES[i]})}
                                                    onClick={() => updatePage(i, Math.min(pages[i] + 1, MAX_PAGES[i]))}>
                                                    <i className="material-icons">keyboard_arrow_right</i>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}

                                <div className="section-spacer"/>
                                <Trigger addTarget={addTarget} targets={targets} name="submission" update={setLocs}/>
                                <div className="section-spacer"/>

                                <section className="submission">
                                    <div className="submission-row">
                                        <div className="submission-left">
                                            <div className="submission-brand">
                                                YOU NEED TO<br/>
                                                FOLLOW THE STEPS<br/>
                                                BELOW TO RUN AIPERF<br/>
                                                AND<br/>
                                                SUBMIT THE RESULT
                                            </div>
                                        </div>
                                        <div className="submission-right">
                                            <ul className="submission-list">
                                                <li>
                                                    Click the "Get AIPerf500", "Get AIPerf-LLM", "Get
                                                    AIPerf-Inference" or
                                                    "Get AIPerf-DeepSeek" button and download the corresponding code.
                                                </li>
                                                <li>
                                                    Run AIPerf according to the steps in README , then collect
                                                    the results. In order to ensure the fairness of evaluation, only
                                                    a part of the codes can be modified to get higher score.<br/>
                                                    See README for details.
                                                </li>
                                                <li>
                                                    Click the &quot;Submit Result&quot; button, fill in necessary
                                                    information,
                                                    then submit files required. See README for details.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="submission-spacer"/>
                                    <div className="submission-row">
                                        <div className="submission-left submission-learn-more">
                                            Click and learn more
                                            <img src={ImgArrow}/>
                                        </div>
                                        <div className="submission-right">
                                            <a className="no-ul" href="aiperf.zip">
                                                <button className="submission-btn">Get AIPerf500</button>
                                            </a>
                                            <a className="no-ul" href="AIPerf-LLM-FastMoE.tar.gz">
                                                <button className="submission-btn">Get AIPerf-LLM</button>
                                            </a>
                                            <br/>
                                            <a className="no-ul" href="AIPerf-Inference.tar.gz">
                                                <button className="submission-btn">Get AIPerf-Inference</button>
                                            </a>
                                            <a className="no-ul" href="AIPerf-DeepSeek.tar.gz">
                                                <button className="submission-btn">Get AIPerf-DeepSeek</button>
                                            </a>
                                            <br/>
                                            <button className="submission-btn submission-btn-primary" onClick={() => {
                                                setSubmitting(true);
                                                setSubmissionState(SUBMISSION_DIALOG_STATE.DEFAULT);
                                            }}>Submit Result
                                            </button>
                                        </div>
                                    </div>
                                </section>

                                <Trigger addTarget={addTarget} targets={targets} name="about" update={setLocs}/>
                                <div className="bottom-spacer"/>

                                <h2>Committee Members</h2>
                                <div className="title-split"/>

                                <div className="committee-row">
                                    <div className="committee-cell">
                                        <CommitteeImg img={ImgBalaji}/>
                                        <div className="committee-info">
                                            <div className="committee-name">Pavan Balaji</div>
                                            <div className="committee-org">Facebook</div>
                                        </div>
                                    </div>
                                    <div className="committee-cell">
                                        <CommitteeImg img={ImgChen}/>
                                        <div className="committee-info">
                                            <div className="committee-name">Wenguang Chen</div>
                                            <div className="committee-org">Tsinghua University</div>
                                        </div>
                                    </div>
                                    <div className="committee-cell">
                                        <CommitteeImg img={ImgHoefler}/>
                                        <div className="committee-info">
                                            <div className="committee-name">Torsten Hoefler</div>
                                            <div className="committee-org">Swiss Federal Institute of Technology
                                                Zurich
                                            </div>
                                        </div>
                                    </div>
                                    <div className="committee-cell">
                                        <CommitteeImg img={ImgZhang}/>
                                        <div className="committee-info">
                                            <div className="committee-name">Yunquan Zhang</div>
                                            <div className="committee-org">China Academy of Sciences</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom-spacer"/>

                                <div className={clsx("backdrop", {shown: submitting})}
                                     onClick={() => setSubmitting(false)}>
                                    {submissionDialog}
                                </div>
                            </main>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <footer>
                <div>
                    <span>AIPerf500 Foundation</span>
                    |
                    <span>aiperf.org</span>
                </div>
                <div>
                    <span>Tel. <a href="tel:+8601062795471">+86 010-62795471</a></span>
                    <span>Email <a href="mailto:zhaijidong@tsinghua.edu.cn">zhaijidong@tsinghua.edu.cn</a></span>
                </div>
            </footer>
        </div>
    );
}

export default App;
