import {leaderBoardScores} from "./config";
import {HoverCellInner} from "../components";

export default {
    name: 'AIPerf-DeepSeek',
    columns: [
        {display: "Manufacturer", key: "manufacturer"},
        {display: "TTFT (P90)", key: "ttft"},
        {display: "TPOT (P90)", key: "tpot"},
        {display: "Throughput", key: "throughput"},
        {display: "Accuracy", key: "accuracy"},
        {display: "Configuration", key: "conf"},
        {display: "Price", key: "price"},
    ],
    data: [

    ].sort((a, b) => parseFloat(a.score) - parseFloat(b.score)),
}
